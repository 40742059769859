.App {
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  background: radial-gradient(circle at 30% -100%, rgb(14, 72, 222), rgb(1, 10, 30) 75.3%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.image1{
  width: 100px;
  height: 100px;
}

.introimage{
  width: auto;
  height: 600px;
}

.detectintroimage{
  width: auto;
  height: 600px;
}

.faceimage{
  width: auto;
  height: 450px;
}

.box{
  /* margin-bottom: 20px; */
  display: flex;
  width: fit-content;
  height: fit-content;
  /* position: fixed; */
}

.box1{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* background-color: blue; */
  height: auto;
  width: 470px;
  margin-right: 20px;
}

.box-buttons{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.h1{
  margin-top: -10px;
}

.resultimage{
  position: relative;
  top: 25px;
  /* bottom: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.resultimageadd{
  position: relative;
  top: 25px;
  /* bottom: 50px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.webcam{
  height:300px;
  width: 450px;
}

.detectresultimage{
  height:250px; 
  width:400px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 0.2s infinite; /* Adjust the duration as needed */
}

@media screen and (max-width: 821px) {
  .App {
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    background: radial-gradient(circle at 30% -100%, rgb(14, 72, 222), rgb(1, 10, 30) 75.3%);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .App-1 {
    position: relative;
    top: 100px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-wrap: wrap;
  }
  
  .image1{
    width: 100px;
    height: 100px;
  }

  .introimage{
    width: auto;
    height: 550px;
  }

  .detectintroimage{
    width: auto;
    height: 520px;
  }

  .faceimage{
    width: auto;
    height: 450px;
  }
  
  .box{
    /* margin-bottom: 20px; */
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-wrap: wrap;
    /* position: fixed; */
  }
  
  .box1{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    width: 400px;
    margin-right: 20px;
    /* position: relative;
    top: 100px; */
  }

  .box2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 520px;
    width: 470px;
  }
  
  .box-buttons{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    height: 90px;
    width: 380px;
    align-content: space-between;
  }
  
  .h1{
    margin-top: -10px;
  }
  
  .resultimage{
    position: relative;
    top: 0px;
    /* bottom: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 400px;
  }
  
  .resultimageadd{
    position: relative;
    top: 25px;
    /* bottom: 50px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
  }
  
  .webcam{
    height:300px;
    width: 450px;
  }

  .detectresultimage{
    height:250px; 
    width:400px;
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blink {
    animation: blink 0.2s infinite; /* Adjust the duration as needed */
  }
}

@media screen and (max-width: 426px) {
  .App {
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    background: radial-gradient(circle at 30% -100%, rgb(14, 72, 222), rgb(1, 10, 30) 75.3%);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .App-1 {
    position: relative;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex-wrap: wrap;
  }
  
  .image1{
    width: 100px;
    height: 100px;
  }

  .introimage{
    width: auto;
    height: 550px;
  }

  .detectintroimage{
    width: auto;
    height: 520px;
  }
  
  .faceimage{
    width: 300px;
    height: 330px;
  }

  .box{
    /* margin-bottom: 20px; */
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-wrap: wrap;
    /* position: fixed; */
  }
  
  .box1{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* background-color: blue; */
    height: auto;
    width: 320px;
    margin-right: 20px;
    flex-wrap: wrap;
    /* position: relative;
    top: 100px; */
  }

  .box2{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 470px;
    flex-wrap: wrap;
  }
  
  .box-buttons{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    height: 90px;
    width: auto;
    align-content: space-between;
  }
  
  .h1{
    margin-top: -10px;
  }
  
  .resultimage{
    position: relative;
    top: 0px;
    /* bottom: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    flex-wrap: wrap;
  }
  
  .resultimageadd{
    position: relative;
    top: 25px;
    /* bottom: 50px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
  }
  
  .webcam{
    height:220px;
    width: 320px;
  }

  .detectresultimage{
    height:200px; 
    width:300px;
  }

  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blink {
    animation: blink 0.2s infinite; /* Adjust the duration as needed */
  }
}